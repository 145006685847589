import { Button } from './components/button'
import { Text } from './components/text'
export default {
  fonts: {
    body: 'HarmonyOS Sans',
    heading: 'HarmonyOS Heading',
    label: 'HarmonyOS Sans',
  },
  colors: {
    primary: {
      main: '#934ECE',
      40: '#F8F8FF', //admin dashboard main bg color
      boxBg: '#FFFFFF',
      muted: '#71717A',
      heading: '#18181B',
      bodyText: '#8F92A1',
      primaryText: '#934ECE',
      500: '#934ECE',
    },
    error: '#EF4444',
    success: '#22C55E',
  },
  components: {
    Text,
    Button,
  },
  styles: {
    global: {
      body: {
        bg: '#FAFAFA',
      },
    },
  },
}
