import { defineStyleConfig } from '@chakra-ui/react'

export const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    backgroundColor: '#934ECE',
    borderRadius: 'base', // <-- border radius is same for all variants and sizes
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4, // <-- px is short for paddingLeft and paddingRight
      py: 3, // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: 'md',
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
  },
  // Two variants: outline and primary
  variants: {
    primary: {
      bg: '#934ECE',
      border: '0px',
      color: 'white',
      _hover: {
        bg: '#7221B8',
        color: 'white',
      },
    },
    outline: {
      bg: '#F2F2F2',
      border: '0px',
      color: '#8F92A1',
      _hover: {
        bg: '#F8F8F9',
        color: '#8F92A1',
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
})
