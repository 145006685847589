import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { RxDashboard } from 'react-icons/rx'
import {
  BsBrightnessLowFill,
  BsFillBarChartLineFill,
  BsTelephone,
} from 'react-icons/bs'
import { BiGroup } from 'react-icons/bi'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { MdOutlineLocationOn } from 'react-icons/md'
import { Starts, Calculations } from 'assets/icons'
type props = {
  children: JSX.Element
}
export default function Sidebar({ children }: props) {
  return (
    <Box as={Flex}>
      <Box
        bg="white"
        py="90px"
        w="100px"
        as={Flex}
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex direction="column">
          <RxDashboard
            style={{
              padding: '15px',
              fontSize: '48px',
              backgroundColor: '#934ECE',
              borderRadius: '5px',
              color: 'white',
            }}
          />

          <HiOutlineOfficeBuilding
            style={{ padding: '15px', fontSize: '48px' }}
          />

          <BsFillBarChartLineFill
            style={{ padding: '15px', fontSize: '48px' }}
          />

          <MdOutlineLocationOn style={{ padding: '15px', fontSize: '48px' }} />
          <Text style={{ padding: '15px', fontSize: '48px' }}>
            <Calculations />
          </Text>
          <BiGroup style={{ padding: '15px', fontSize: '48px' }} />
          <BsTelephone style={{ padding: '15px', fontSize: '48px' }} />
          <Text style={{ padding: '15px', fontSize: '48px' }}>
            <Starts />
          </Text>
        </Flex>
        <Flex>
          <BsBrightnessLowFill
            style={{
              padding: '15px',
              fontSize: '60px',
              borderRadius: '50px',
              background: '#FFFFFF',
              border: '3px solid #F1F1F1',
            }}
          />
        </Flex>
      </Box>
      <Box p={0} m={0} as={Flex} flex="1" bg="primary.40">
        {children}
      </Box>
    </Box>
  )
}
