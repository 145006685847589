import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from 'views/dashoard'
const routes = [
  {
    path: '/',
    Component: Dashboard,
  },
]

export default function AppRoutes() {
  return (
    <Routes>
      {routes?.map(({ path, Component }) => (
        <Route key={path} element={<Component />} path={path} />
      ))}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
