import React from 'react'

import Header from 'components/header'
import { BrowserRouter } from 'react-router-dom'
import './app.scss'
import Routes from 'routes'
import Sidebar from 'components/sidebar'

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Sidebar>
        <Routes />
      </Sidebar>
    </BrowserRouter>
  )
}

export default App
