import {
  Stastics,
  Transactions,
  ListingBoard,
  PotentialClient,
} from 'utils/types/dashboard.type'
export const transactionData: Transactions[] = [
  {
    imgUrl: 'https://bit.ly/dan-abramov',
    tradeCurrency: 'USDT,0x000...000',
    tradeApartment: 'Luxurious Apartments',
    tradeAmmount: '$182.94/Jan 17, 2023',
    status: 'Received',
  },
  {
    imgUrl: 'https://bit.ly/dan-abramov',
    tradeCurrency: 'USDT,0x000...000',
    tradeApartment: 'Luxurious Apartments',
    tradeAmmount: '$182.94/Jan 17, 2023',
    status: 'Received',
  },
  {
    imgUrl: 'https://bit.ly/dan-abramov',
    tradeCurrency: 'USDT,0x000...000',
    tradeApartment: 'Luxurious Apartments',
    tradeAmmount: '$182.94/Jan 17, 2023',
    status: 'Pending',
  },
  {
    imgUrl: 'https://bit.ly/dan-abramov',
    tradeCurrency: 'USDT,0x000...000',
    tradeApartment: 'Luxurious Apartments',
    tradeAmmount: '$182.94/Jan 17, 2023',
    status: 'Canceled',
  },
]
export const statisticData: Stastics[] = [
  {
    title: 'TOTAL DEALS',
    amount: 12426,
    ratio: 36,
  },
  {
    title: 'KYCED CUSTOMERS',
    amount: 238485,
    ratio: 14,
  },
  {
    title: 'TOTAL SALES',
    amount: 238485,
    ratio: 14,
  },
]

export const listingData: ListingBoard[] = [
  {
    apartmentType: 'Offering',
    amountStatus: '$ 120,000',
  },
  {
    apartmentType: 'APR',
    amountStatus: '17%',
  },
  {
    apartmentType: 'ARV',
    amountStatus: '80%',
  },
  {
    apartmentType: 'Trem',
    amountStatus: '1 Month',
  },
]

export const clientData: PotentialClient[] = [
  {
    imgUrl: 'https://bit.ly/dan-abramov',
    name: 'Jenny Wilson',
    email: 'info.user@example.com',
    amount: 11234,
    location: 'Austin',
  },
  {
    imgUrl: 'https://bit.ly/dan-abramov',
    name: 'Devon Lane',
    email: 'info.user@example.com',
    amount: 11159,
    location: 'New York',
  },
  {
    imgUrl: 'https://bit.ly/dan-abramov',
    name: 'Jane Cooper',
    email: 'info.user@example.com',
    amount: 10483,
    location: 'Toledo',
  },
  {
    imgUrl: 'https://bit.ly/dan-abramov',
    name: 'Dianne Russell',
    email: 'info.user@example.com',
    amount: 9084,
    location: 'Nevada',
  },
  {
    imgUrl: 'https://bit.ly/dan-abramov',
    name: 'Ross Gellar',
    email: 'info.user@example.com',
    amount: 9084,
    location: 'Capsicum',
  },
]
