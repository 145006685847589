import React from 'react'
import ReactApexChart from 'react-apexcharts'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  categories: string[]
  height: string | number
  width: string | number
  colors?: string[]
}

export default function Graph({
  data,
  categories,
  height,
  width,
  colors,
}: Props) {
  const config = React.useMemo(
    () => ({
      series: [
        {
          name: 'STOCK ABC',
          data,
        },
      ],
      options: {
        colors: colors || ['#924ece'],
        tooltip: {
          enabled: true,
        },
        chart: {
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: true,
        },
        yaxis: {
          show: true,
        },
        xaxis: {
          categories,
        },
        legend: {
          show: true,
        },
        dataLabels: {
          enabled: false,
        },
      },
    }),
    [],
  )
  return (
    <div id="chart">
      <ReactApexChart
        options={config.options}
        series={config.series}
        type="area"
        height={height}
        width={width}
      />
    </div>
  )
}
