import React from 'react'
import {
  Box,
  Link,
  Button,
  Container,
  Flex,
  HStack,
  Stack,
  Text,
  Image,
  ButtonGroup,
  Tag,
  TagLabel,
} from '@chakra-ui/react'
import { Transactions } from 'utils/types/dashboard.type'
// import { FaGreaterThan } from 'react-icons/fa'
import Graph from 'components/graph'
import { ChevronRightIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import { statisticData } from 'utils/data/dashboard.data'
import { createColumnHelper } from '@tanstack/react-table'
import {
  transactionData,
  listingData,
  clientData,
} from 'utils/data/dashboard.data'
import DataTable from 'components/table'
import {
  Stastics,
  ListingBoard,
  PotentialClient,
} from 'utils/types/dashboard.type'
const columnHelper = createColumnHelper<Transactions>()
const columns = [
  columnHelper.accessor('imgUrl', {
    cell: info => <Image boxSize="36px" rounded="full" src={info.getValue()} />,
    header: 'Person',
  }),
  columnHelper.accessor('tradeCurrency', {
    cell: info => (
      <Flex direction="column">
        <Text color="primary.heading" className="fs--14 fw--700">
          {info.getValue().split(',')[0]}
        </Text>
        <Text color="primary.bodyText" className="fs--14 fw--500">
          <Link href="https://chakra-ui.com" isExternal>
            {info.getValue().split(',')[1]} <ExternalLinkIcon mx="2px" />
          </Link>
        </Text>
      </Flex>
    ),
    header: 'Currency Amount',
  }),
  columnHelper.accessor('tradeApartment', {
    cell: info => (
      <Flex direction="column">
        <Text color="primary.bodyText" className="fs--14 fw--500">
          <Link href="https://chakra-ui.com" isExternal>
            {info.getValue()} <ExternalLinkIcon mx="2px" />
          </Link>
        </Text>
      </Flex>
    ),
    header: 'Apartment',
  }),
  columnHelper.accessor('tradeAmmount', {
    cell: info => (
      <Flex direction="column">
        <Text color="primary.heading" className="fs--14 fw--700">
          {info.getValue().split('/')[0]}
        </Text>
        <Text color="primary.bodyText" className="fs--14 fw--500">
          {info.getValue().split('/')[1]}
        </Text>
      </Flex>
    ),
    header: 'Ammount',
  }),
  columnHelper.accessor('status', {
    cell: info => (
      <>
        {info.getValue().includes('Received') ? (
          <Tag borderRadius="full" variant="solid" bg="#DCFCE7">
            <TagLabel px="10px" className="fs--12 fw--500" color="#14532D">
              {info.getValue()}
            </TagLabel>
          </Tag>
        ) : null}
        {info.getValue().includes('Pending') ? (
          <Tag borderRadius="full" variant="solid" bg="#FEF9C3">
            <TagLabel px="10px" className="fs--12 fw--500" color="#6A620C">
              {info.getValue()}
            </TagLabel>
          </Tag>
        ) : null}
        {info.getValue().includes('Canceled') ? (
          <Tag borderRadius="full" variant="solid" bg="#FEE2E2">
            <TagLabel px="10px" className="fs--12 fw--500" color="#7B1414">
              {info.getValue()}
            </TagLabel>
          </Tag>
        ) : null}
      </>
    ),
    header: 'Status',
  }),
]
export default function Dashboard() {
  return (
    <Container m={0} maxWidth="1280px">
      <HStack pt="35px" spacing="25px" alignItems="start" minWidth="800px">
        <Box>
          {/* Statistic boxes Start*/}
          <HStack spacing="25px">
            {statisticData.map((item: Stastics, index: number) => {
              return (
                <Box
                  key={index}
                  padding="15px"
                  rounded="10px"
                  w="100%"
                  bg="primary.boxBg"
                  border="1px solid #F2F2F2"
                >
                  <Text className="fs--12 fw--400" color="primary.muted">
                    {item?.title}
                  </Text>
                  <Flex mt="11px" align="center" justify="space-between">
                    <Text className="fs--20 fw--700" color="primary.heading">
                      ${item?.amount}
                    </Text>
                    <Text className="fs--14 fw--400" color="success">
                      +{item?.ratio}%{' '}
                    </Text>
                  </Flex>
                </Box>
              )
            })}
          </HStack>
          {/* Graph Box Start*/}
          <Box
            my="25px"
            p="32px"
            boxShadow="0px 2px 6px rgba(13, 10, 44, 0.08)"
            bg="primary.boxBg"
            rounded="20px"
          >
            <Flex
              align="center"
              pb="15px"
              borderBottom="1px solid #E5E5EF"
              justify="space-between"
            >
              <Box>
                <Text className="fs--16 fw--500" color="primary.muted">
                  Funds Report
                </Text>
                <Text className="fs--20 fw--700" color="primary.heading">
                  Total Summary of Funds
                </Text>
              </Box>
              <ButtonGroup p="10px 15px" size="sm" isAttached>
                <Button variant="outline" className="fs--12 fw--400">
                  Daily
                </Button>
                <Button variant="outline" className="fs--12 fw--400">
                  Weekly
                </Button>
                <Button className="fs--12 fw--400">Monthly</Button>
              </ButtonGroup>
            </Flex>
            <Graph
              width="100%"
              height="300px"
              data={[1, 3, 4, 5, 6, 5]}
              categories={['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']}
            />
          </Box>
          {/* Graph Box End*/}
          {/* Transaction History box start */}
          <Stack
            p="32px"
            boxShadow="0px 2px 6px rgba(13, 10, 44, 0.08)"
            bg="white"
            rounded="10px"
          >
            <Flex alignItems="center" justify="space-between">
              <Text className="fs--20 fw--700" color="primary.heading">
                Transactions
              </Text>
              <Text className="fs--14 fw--400" color="primary.primaryText">
                See All Transactions <ChevronRightIcon ml="2px" />
              </Text>
            </Flex>
            <Box pt="40px">
              <DataTable data={transactionData} columns={columns} />
            </Box>
          </Stack>
          {/* Transaction History box end */}
          {/* Statistic boxes End*/}
        </Box>
        <Box w="378px">
          <Box p="34px 25px" bg="white" rounded="10px" mb="15px">
            <Flex mb="25px" justify="space-between" align="center">
              <Text className="fs--20 fw--700" color="primary.heading">
                Listing Board
              </Text>
              <Text className="fs--14 fw--400" color="primary.primaryText">
                View all
              </Text>
            </Flex>
            <Stack mb="25px" spacing={4} direction="row" align="center">
              <Button className="fs--14 fw--400" w="156px">
                Recent Listed
              </Button>
              <Button
                bg="#F8F8F9"
                className="fs--14 fw--400"
                color="black"
                w="156px"
              >
                Pending List
              </Button>
            </Stack>
            <Box
              bgImage="url('https://bit.ly/dan-abramov')"
              height="190px"
              backgroundSize="cover"
              bgPosition="center"
              bgRepeat="no-repeat"
              p="16px 10px"
              mb="25px"
              rounded="4px"
            >
              <Flex alignItems="center" justifyContent="space-between">
                <Button
                  bg="white"
                  h="23px"
                  color="primary.primaryText"
                  className="fs--10 fw--300"
                >
                  Listed 10 mints ago
                </Button>
                <Button
                  bg="white"
                  h="23px"
                  color="primary.primaryText"
                  className="fs--10 fw--300"
                >
                  Location
                </Button>
              </Flex>
            </Box>
            <Text ml="15px" className="fs--18 fw--700" color="primary.heading">
              Luxurious Apartments
            </Text>
            <Box px="15px" mt="15px">
              {listingData.map((item: ListingBoard, index: number) => {
                return (
                  <Flex
                    key={index}
                    mb="10px"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text className="fs--14 fw--300" color="primary.bodyText">
                      {item?.apartmentType}
                    </Text>
                    <Text className="fs--14 fw--400" color="#3E4958">
                      {item?.amountStatus}
                    </Text>
                  </Flex>
                )
              })}
            </Box>
          </Box>
          {/* Listing Box is ended above */}
          {/* Potential Clients Box is Start */}
          <Box mt="15px" p="34px 25px" bg="white" rounded="10px">
            <Text color="primary.heading" className="fs--20 fw--700" pb="40px">
              Potential Clients
            </Text>
            {clientData.map((item: PotentialClient, index: number) => {
              return (
                <HStack
                  key={index}
                  mt={index === 0 ? '40px' : '0px'}
                  mb="20px"
                  align="center"
                  justify="space-between"
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    <Image
                      borderRadius="full"
                      boxSize="36px"
                      src={item?.imgUrl}
                      alt="Dan Abramov"
                    />
                    <Box ml="10px">
                      <Text color="primary.heading" className="fs--16 fw--500">
                        {item?.name}
                      </Text>
                      <Text
                        color="primary.bodyText"
                        mt="2px"
                        className="fs--12 fw--400"
                      >
                        {item?.email}
                      </Text>
                    </Box>
                  </Flex>
                  <Box>
                    <Text color="primary.heading" className="fs--16 fw--500">
                      ${item?.amount}
                    </Text>
                    <Text
                      color="primary.bodyText"
                      textAlign="end"
                      mt="2px"
                      className="fs--12 fw--400"
                    >
                      {item?.location}
                    </Text>
                  </Box>
                </HStack>
              )
            })}
            <Flex my="45px" justifyContent="center" alignItems="center">
              <Text className="fs--11 fw--500" mr="15px">
                SEE ALL CUSTOMER
              </Text>
              <ChevronRightIcon className="fs--11 fw--300" />
            </Flex>
          </Box>
          {/* Potential Clients Box is end */}
        </Box>
      </HStack>
    </Container>
  )
}
