import React from 'react'
import { Flex, Text } from '@chakra-ui/layout'
import logo from 'assets/images/logo.svg'
import { Image } from '@chakra-ui/image'
import { ButtonGroup } from '@chakra-ui/button'
import { BsBell } from 'react-icons/bs'
import { RxEnvelopeClosed } from 'react-icons/rx'
import './header.scss'
import { Link } from 'react-router-dom'
import { useIsLogin } from '../../hooks/index'
import { Avatar, AvatarBadge } from '@chakra-ui/react'
// const { REACT_APP_SOCIAL_MEDIA_APP } = process.env
export default function Header() {
  const isLogin = useIsLogin()
  return (
    <Flex
      bg="white"
      as="header"
      p="1.3rem"
      alignItems="center"
      boxShadow=" 0px 0px 15px 0px #0000001A"
      justifyContent="space-between"
    >
      <Link to="/">
        <Image src={logo} />
      </Link>
      {!isLogin && (
        <ButtonGroup as={Flex} alignItems="center" gap="4" pr="2rem">
          {/* <RxEnvelopeClosed /> */}
          <Avatar
            bg="transparent"
            color="black"
            size="md"
            icon={<RxEnvelopeClosed />}
          >
            <AvatarBadge
              as={'image'}
              top="0px"
              right="10px"
              boxSize="1em"
              border="0px"
              bg="#934ECE"
            >
              <Text className="fs--11">2</Text>
            </AvatarBadge>
          </Avatar>
          <BsBell fontSize="22px" />
          <Image
            borderRadius="full"
            boxSize="36px"
            src="https://bit.ly/dan-abramov"
            alt="Dan Abramov"
          />
        </ButtonGroup>
      )}
    </Flex>
  )
}
